html {
    scroll-behavior: smooth;
}

.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(211, 211, 211, 0.6); /* Light grey with 60% opacity */
    padding: 0.2rem;
    position: sticky;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(6px);
}

.app__navbar-logo img {
    width: 135px !important;
    height: 90px;
    margin-left: 75px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.app__navbar-links a,
.app__navbar-smallscreen_links a {
    text-decoration: none;
    color: #000;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease;
}

.app__navbar-links a:hover,
.app__navbar-smallscreen_links a:hover {
    color: #B06544;
}

.app__navbar-links a::before,
.app__navbar-smallscreen_links a::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #B06544;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.app__navbar-links a:hover::before,
.app__navbar-smallscreen_links a:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-lightgrey);
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-black);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-black);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-gray);
}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }
    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 0.03rem 0.4rem;

    }
    .app__navbar-logo img {
        width: 140px!important;
        margin-left: 0;
        height: 80px;
    }
}