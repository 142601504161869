.app__header {
    background-color: #DCD7C2;

}

.app__header-h1{
    font-family: var(--font-base);
    color: var(--color-black);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
}

.app__wrapper-img img {
    width: 80%;
}






@media screen and (max-width: 650px ) {
    .app__header-h1 {
        font-size: 40px;







    }

}