/* General Styling */
.app__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

}

/* Text and Info Area Styling */
.app__wrapper_info {
    flex: 1;
    text-align: left;

}


/* Adjustments for p and h1 tags */
.p__opensans-about,
.headtext__cormorant {
    margin-bottom: 10px;
}

/* Divide the flex container into equal sections */
.app__wrapper_info,
.app__wrapper_img,
.contact-form {
    flex: 1;
}

/* Image Area Styling */
.app__wrapper_img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.app__wrapper_img img {
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
}

/* Contact Form Styling */
.contact-form {
    flex: 1;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
}

.contact-form h2,
.contact-form input,
.contact-form textarea,
.contact-form button {
    width: 100%;
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form button:hover {
    background-color: #c0a76b;
}

/* Validation Error Message Styling */
.ValidationError {
    color: red;
    margin-bottom: 10px;
    display: block;
}


.app__wrapper {
    justify-content: space-around; /* or space-evenly, try experimenting with these */
}

.app__wrapper_img {
    margin-right: 75px; /* Add some right margin */
}

/* Responsive Design */
@media screen and (max-width: 900px) {
    .app__wrapper {
        flex-direction: column;
    }

    .app__wrapper_info,
    .app__wrapper_img,
    .contact-form {
        width: 100%; /* Ensures full width on mobile */
        margin-bottom: 20px; /* Adjust as needed */
    }

    .app__wrapper_img {
        order: 1;
        margin-bottom: 10px; /* Adds margin below the store image */
    }

    .smiley-container {
        order: 2;
        margin-bottom: 20px; /* Adds margin below the smiley image */
    }

    .app__wrapper_img img,
    .food-control-report-img {
        margin: 20px; /* Adds margin around each image */
    }
}




.food-control-report-img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: rotate(-5deg);
    max-width: 200px; /* Adjust the size to fit your design */
    border-radius: 10px;
    transition: transform 0.3s ease;

    /* Optional: add a hover effect */
    &:hover {
        transform: rotate(0deg) scale(1.05);
    }
}

