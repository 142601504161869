 
.app__aboutus{
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    padding-bottom: 50rem;


}

.app__aboutus-overlay img {
    width: 459px;
    height: 500px;
    z-index: 1;
    margin-left: 66px;

}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}
.spoon__img-reverse {
    transform: scaleX(-1);
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column ;

    text-align: right;

}

.app__aboutus-content_knife{
    margin: 2rem 4rem;
}
.app__aboutus-content_knife img{
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column ;

    text-align: left;
    
}

.app__aboutus-content_history p{
    margin: 3rem 0;
}

.app__aboutus-content_about p {
    margin: 1rem 0;

}

@media screen and (max-width: 2000px){
    .app__aboutus-content_knife img{
        height: 1110px;
    }

    .app__aboutus-content_about p{
        margin: 3.3rem 0;
        margin-bottom: 3rem;
    }
    .app__aboutus-content_history p{
        margin: 3rem 0;
        margin-bottom: 3rem;
    }

}

@media screen and (max-width: 900px){
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }


}

@media screen and (max-width: 650px){

   

    .app__aboutus-content_knife img{
        height: 0px;
        width: 72px;
        display: none;
       
    }

    .app__aboutus-content_knife {
       height: 0px;
        margin-right: 1.8rem;
      
    }

}