
.section__padding {
    padding: 4rem 6rem;
}

.p__opensans-about a.phone-number {
    color: #FFFFFF; /* White color for the text */
    box-shadow: inset 0 -1px 0 #D3D3D3; /* Light Grey underline */
    text-decoration: none; /* Removing the default underline */
    cursor: pointer;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__bg {
    background: url('./assets/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}

.app__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 100vh;
}

.app__wrapper_info {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.app__wrapper_img {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.app__wrapper_img img {
    width: 100%; /* Ensures the image takes up the full width of its container */
    height: auto; /* Keeps the image's aspect ratio */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.app__wrapper_img img:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.05); /* Enlarges the image slightly */
}

.app__wrapper_img img {
    width: 80%;
}

.custom__button {
    background-color: #B06544;
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 4px; /* Slightly increased for a softer edge */
    border: none;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for shadow and position */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for unhovered state */
    margin-bottom: 20px;
}

.custom__button:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Slightly larger shadow for hovered state */
    transform: translateY(-2px); /* Slight upward motion for a lifting effect */
}

.custom__button:active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Smaller shadow for active/clicked state */
    transform: translateY(1px); /* Slight downward motion for a pressing effect */
}

.p__cormorant {
    font-family: var(--font-base);
    color: var(--color-black);
    font-weight: 700;
    letter-spacing: 0.04em;

    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 23px;
}


.p__cormorant-white {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 700;
    letter-spacing: 0.04em;

    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 23px;
}

.p__opensans {
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 400;
    letter-spacing: 0.04em;

    line-height: 28px;
    font-size: 16px;
}

.p__opensans-about {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;

    line-height: 28px;
    font-size: 16px;
}


.headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;

}

.spoon__img {
    width: 45px;
}

@media screen and (min-width: 2000px) {
    .custom__button,
    .p__cormorant {
        font-size: 37px;
        line-height: 67px;
    }

    .p__opensans {
        font-size: 30px;
        line-height: 50px;
    }

    .headtext__cormorant {
        font-size: 150px;
        line-height: 210px;
    }

    .spoon__img {
        width: 80px;
    }
}

@media screen and (max-width: 1150px) {
    .app__wrapper {
        flex-direction: column;
    }

    .app__wrapper_img {
        margin: 5rem 0 0 0;
    }

    .app__wrapper_img-reverse {
        margin: 0 0 5rem 0;
    }

    .app__wrapper_img img {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    .section__padding {
        padding: 4rem;
    }
}

@media screen and (max-width: 650px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .p__cormorant {
        font-size: 21px;
    }

    .p__opensans {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .p__opensans {
        font-size: 12px;
    }

    .p__cormorant {
        font-size: 19px;
    }

    .headtext__cormorant {
        font-size: 45px;
        line-height: 70px;
    }
    
}
/* Fullscreen overlay for the popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85); /* Dark semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensures the popup appears above all other content */
    animation: fadeIn 0.5s ease; /* Smooth fade-in animation */
    backdrop-filter: blur(12px); /* Frosted-glass effect */
}

/* Popup content container */
.popup-content {
    background: rgba(255, 255, 255, 0.1); /* Frosted-glass background */
    backdrop-filter: blur(25px); /* Enhanced frosted-glass effect */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
    border-radius: 20px; /* Smooth rounded edges */
    padding: 40px 30px;
    max-width: 450px;
    width: 90%;
    text-align: center;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5); /* Elegant shadow for depth */
    animation: slideUp 0.6s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth slide-up effect */
    position: relative;
    overflow: hidden;
}

/* Subtle glow effect */
.popup-content::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    animation: spinGlow 12s linear infinite; /* Gentle spinning effect */
    z-index: 0; /* Behind the popup content */
    pointer-events: none; /* Prevent interaction */
}

/* Popup title */
.popup-content h2 {
    font-size: 2rem;
    color: #ffffff; /* Bright white text for contrast */
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.05em; /* Subtle spacing for elegance */
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.7); /* Clean and elegant shadow */
    animation: fadeSlideDown 0.6s ease; /* Smooth entry animation */
}

/* Popup message text */
.popup-content p {
    font-size: 1rem;
    color: #f1f1f1; /* Softer white for readability */
    line-height: 1.8;
    margin-bottom: 25px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Subtle shadow for better readability */
    animation: fadeSlideDown 0.7s ease; /* Staggered animation */
}

/* Action button */
.popup-content button {
    background: linear-gradient(135deg, rgba(240, 240, 240, 0.2), rgba(255, 255, 255, 0.4)); /* Subtle gradient */
    color: #ffffff; /* Clean white text */
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, 0.4); /* Subtle border for structure */
    border-radius: 30px; /* Fully rounded for elegance */
    padding: 12px 30px;
    cursor: pointer;
    box-shadow: 0 8px 25px rgba(255, 255, 255, 0.2); /* Soft glowing effect */
    transition: all 0.4s ease; /* Smooth hover transitions */
    animation: fadeSlideDown 0.8s ease; /* Staggered entry */
}

/* Button hover effect */
.popup-content button:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(240, 240, 240, 0.6)); /* Lighter gradient */
    transform: translateY(-3px) scale(1.05); /* Slight lift */
    box-shadow: 0 12px 35px rgba(255, 255, 255, 0.4); /* Slightly stronger glow */
}

/* Button active effect */
.popup-content button:active {
    transform: scale(0.95); /* Pressed effect */
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2); /* Reduced shadow */
}

/* Animations */

/* Fade-in animation for the overlay */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Slide-up animation for the popup content */
@keyframes slideUp {
    from {
        transform: translateY(50px) scale(0.95);
        opacity: 0;
    }
    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

/* Spin animation for the decorative glow */
@keyframes spinGlow {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Fade and slide-down animation for text elements */
@keyframes fadeSlideDown {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive Design Adjustments */
@media screen and (max-width: 768px) {
    .popup-content {
        padding: 30px 20px;
        max-width: 400px;
    }

    .popup-content h2 {
        font-size: 1.8rem;
    }

    .popup-content p {
        font-size: 0.95rem;
    }

    .popup-content button {
        font-size: 1rem;
        padding: 10px 25px;
    }
}