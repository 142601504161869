@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.app__specialMenu {
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_left, .app__specialMenu-menu_right {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 410px;
    margin: 0 2rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px; /* Optional: if you want rounded corners */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.app__specialMenu-menu_img img:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.05); /* Slightly enlarges the image */
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}




.additionals-heading {
    color: #DCCA87;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    text-align: center;
    border-bottom: 2px solid #DCCA87;
    padding-bottom: 10px;
    display: inline-block;
}

@media screen and (max-width: 2000px){



}

@media screen and (max-width: 1150px){
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img{
        margin: 3rem 0;
    }

}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_left,
    .app__specialMenu-menu_right {
        width: 90%; /* Adjust width for mobile */
    }

    .app__specialMenu-menu_img {
        width: 100%;
        margin: 0 0 2rem 0; /* Adjust margin for mobile */
    }

    .app__specialMenu-additional-items {
        width: 90%; /* Adjust width for mobile */
    }
}

@media screen and (min-width: 651px) {
    .app__specialMenu-menu {
        flex-direction: row;
    }

    .app__specialMenu-additional-items {
        width: 100%;
    }
}



